<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="政策名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入礼包名称"></a-input>
					</a-form-item>

					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="政策类型" name="useLimit">
						<a-select v-model:value="formState.useLimit" allow-clear
							style="width: 180px;" placeholder="请选择政策类型">
							<a-select-option :value="1">影片</a-select-option>
							<a-select-option :value="2">演出</a-select-option>
							<a-select-option :value="3">卖品</a-select-option>
							<a-select-option :value="4">商城</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row v-show="showAll">
					<a-form-item label="创建用户" name="createUser" class="ui-form__item">
						<a-input v-model:value="formState.createUser" placeholder="请输入创建用户"></a-input>
					</a-form-item>
					
					<a-form-item label="创建时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['coupon_snack_rule_add']" type="primary"
							@click="onAdd">新增优惠券政策</a-button>
							
						<a style="margin-left: 20px" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'useLimit'">
							{{ ['影片', '演出', '卖品', '商城'][record.useLimit - 1] }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'updateTime'">
							{{ record.updateTime ? transDateTime(record.updateTime) : transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'updateUser'">
							{{ record.updateUserName || record.createUserName }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							<a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
							<a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onEdit(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_rule_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_rule_disabled']" v-if="record.isDisabled"
											@click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_rule_disabled']" v-else
											@click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['coupon_snack_rule_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import sendGift from '@/components/sendGift/index.vue'
	import {
		getRulesList,
		deleteRules,
		disabledRules
	} from "@/service/modules/coupon";
	export default {
		components: {
			Icon,
			temp,
			sendGift
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				id: 0,
				loading: false,
				showAll: false,
				userListId: [],
				searchData: {},
				formState: {},
				time: [],
				columns: [{
					title: '政策名称',
					dataIndex: 'title',
				}, {
					title: '政策类型',
					key: 'useLimit',
				}, {
					title: '创建时间',
					key: 'createTime',
				}, {
					title: '创建用户',
					dataIndex: 'createUserName',
				}, {
					title: '最新修改时间',
					key: 'updateTime'
				}, {
					title: '最新修改人',
					key: 'updateUser'
				}, {
					title: '状态',
					key: 'isDisabled',
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			onAdd() {
				this.id = 0;
				this.isSee = false;
				this.isEdit = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isSee = false;
				this.isEdit = true;
				this.showModal = true;
			},
			onSee(item) {
				this.id = item.id;
				this.isSee = true;
				this.isEdit = false;
				this.showModal = true;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getRulesList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该政策吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await disabledRules({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确认删除该政策吗？',
					onOk: async() => {
						this.loading = true;
						try {
							let ret = await deleteRules({
								id: item.id,
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>